import "@styles/page.scss";
import Swiper, { Pagination, Navigation, HashNavigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { teleportElement, getSiblings } from "./scripts/utils";

document.addEventListener(
  "DOMContentLoaded",
  () => {
    /* Auto Imports */
    const assetContext = require.context(".", true, /\.(asset)\.(.*?)$/);
    assetContext.keys().forEach(assetContext);
    const styleContext = require.context(".", true, /\.(style)\.(.*?)$/);
    styleContext.keys().forEach(styleContext);
    const runtimeContext = require.context(".", true, /\.(runtime)\.(.*?)$/);
    runtimeContext.keys().forEach(runtimeContext);
  },
  { once: true },
);

window.addEventListener("load", () => {
  getMediaQueryContactPage();
  teleportCoordsOFHarbours();
});

window.addEventListener("resize", () => {
  teleportCoordsOFHarbours();
});

function getDataIndex(index) {
  const element = document.getElementsByClassName("page-portsSwiper")[0];
  return element.getAttribute("data-index-" + index);
}

const page_ports_swiper = new Swiper(".page-portsSwiper", {
  modules: [Pagination],
  allowTouchMove: false,

  pagination: {
    el: ".page-ports__pagination",
    clickable: true,
    bulletClass: "swiper-pagination-bullet page-ports__bullet",
    bulletActiveClass: "swiper-pagination-bullet-active page-ports__bullet_active",
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + getDataIndex(index + 1) + "</span>";
    },
  },

  breakpoints: {
    768: {
      spaceBetween: 13,
      slidesPerView: "auto",
    },
  },
});

const images_swiper = new Swiper(".imagesSwiper", {
  modules: [Navigation],
  slidesPerView: 1,
  spaceBetween: 40,

  navigation: {
    prevEl: ".posts__prev",
    nextEl: ".posts__next",
    disabledClass: "posts__nav_disabled",
  },
});

const posts_swiper = new Swiper(".postsSwiper", {
  modules: [Navigation],
  slidesPerView: 1,
  spaceBetween: 40,

  navigation: {
    prevEl: ".posts__prev",
    nextEl: ".posts__next",
    disabledClass: "posts__nav_disabled",
  },

  breakpoints: {
    600: {
      slidesPerView: 2,
    },
    1285: {
      slidesPerView: 3,
    },
  },
});

const discoveries_swiper = new Swiper(".discoveriesSwiper", {
  modules: [Navigation],
  slidesPerView: 1.05,
  spaceBetween: 23,

  navigation: {
    prevEl: ".discoveries__prev",
    nextEl: ".discoveries__next",
    disabledClass: "discoveries__nav_disabled",
  },

  breakpoints: {
    600: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 13,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 13,
    },
  },
});

const values_swiper = new Swiper(".valuesSwiper", {
  modules: [Navigation],
  slidesPerView: 1.05,
  spaceBetween: 12,

  navigation: {
    prevEl: ".values__prev",
    nextEl: ".values__next",
    disabledClass: "values__nav_disabled posts__nav_disabled",
  },

  breakpoints: {
    576: {
      slidesPerView: 2,
    },
    1285: {
      slidesPerView: 3,
    },
    1500: {
      slidesPerView: 4,
    },
  },
});

const directors_swiper = new Swiper(".directorsSwiper", {
  modules: [Navigation],
  spaceBetween: 22,
  centeredSlides: true,
  effect: "fade",
  lazy: true,
  slidesPerView: "auto",

  fadeEffect: {
    crossFade: true,
  },

  navigation: {
    prevEl: ".directors__prev",
    nextEl: ".directors__next",
    disabledClass: "directors__nav_disabled posts__nav_disabled",
  },

  breakpoints: {
    768: {
      slidesPerView: 2,
      centeredSlides: false,
      centerInsufficientSlides: true,
    },
    992: {
      slidesPerView: 3,
      centeredSlides: false,
      centerInsufficientSlides: true,
    },
    1100: {
      slidesPerView: 4,
      centeredSlides: false,
      centerInsufficientSlides: true,
    },
  },
});

if (!!document.querySelector(".organizationSwiper")) {
  var initOrganization = false;
  var organization_swiper = null;

  function organizationResponsive() {
    if (window.innerWidth <= 768) {
      if (!initOrganization) {
        initOrganization = true;
        organization_swiper = new Swiper(".organizationSwiper", {
          modules: [Navigation],
          spaceBetween: 22,
          centeredSlides: true,
          effect: "fade",
          lazy: true,
          slidesPerView: "auto",

          fadeEffect: {
            crossFade: true,
          },

          navigation: {
            prevEl: ".organization__prev",
            nextEl: ".organization__next",
            disabledClass: "organization__nav_disabled posts__nav_disabled",
          },

          breakpoints: {
            1200: {
              slidesPerView: 5,
              centeredSlides: false,
              centerInsufficientSlides: true,
            },
          },
        });
      }
    } else if (initOrganization) {
      organization_swiper.destroy();
      initOrganization = false;
    }
  }
  organizationResponsive();
  window.addEventListener("resize", organizationResponsive);
}

if (!!document.querySelector(".monthSwiper")) {
  const month_swiper = new Swiper(".monthSwiper", {
    modules: [Navigation, HashNavigation],
    spaceBetween: 6,
    slidesPerView: 7,
    hashNavigation: {
      watchState: true,
      replaceState: true,
    },
    navigation: {
      prevEl: ".month__prev",
      nextEl: ".month__next",
      disabledClass: "month__nav_disabled posts__nav_disabled",
    },
    on: {
      init: function () {},
    },
  });
  month_swiper.slideTo(new Date().getMonth());
}

// functions for the contact page
function getMediaQueryContactPage() {
  if (!!document.querySelector("[contact]")) {
    return getComputedStyle(document.querySelector("[contact]")).getPropertyValue("--breakpoint");
  }
}

function teleportCoordsOFHarbours() {
  if (!!document.querySelector("[contact]")) {
    if (window.matchMedia(`(min-width: ${getMediaQueryContactPage()}px)`).matches) {
      teleportElement("[contact__body]", "[contact__panelRight]");
    } else {
      [].forEach.call(document.querySelectorAll("[contact__body]"), function (el, index) {
        document.querySelector(`[contact__wrap]:nth-child(${index + 1})`).appendChild(el);
      });
    }
  }
}

if (!!document.querySelector("[contact]")) {
  if (window.matchMedia(`(min-width: ${getMediaQueryContactPage()}px)`).matches) {
    [].forEach.call(document.querySelectorAll("[contact__wrap]"), function (el) {
      el.addEventListener("click", function () {
        let listWraps = getSiblings(this);

        listWraps.forEach((value) => {
          value.classList.remove("contact__wrap_active");
          value.querySelector("[contact__head]").classList.remove("contact__head_active");
        });
      });
    });
  }
}
